import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Gps from "/src/components/img/gps"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

export default () => (
  <Layout>
    <SEO title="Vysílané signály GNSS (GPS)" />
    <h1>Vysílané signály GNSS (GPS)</h1>

    <p><strong>GNSS </strong>je zkratkou pro <strong>glob&aacute;ln&iacute; navigačn&iacute; satelitn&iacute; syst&eacute;m. </strong>Obecně jde o službu, technologii umožňuj&iacute;c&iacute; pomoc&iacute; sign&aacute;lu ze satelitn&iacute;ch družic určit svou polohu, rychlost a čas s velkou přesnost&iacute;.</p>
    <hr /><h2>Sign&aacute;ly GPS</h2>
    <p>Mezi nosn&eacute; frekvence syst&eacute;mu GPS patř&iacute;:</p>
    <ul>
    <li><strong>L1 </strong>(1575,42MHz 19cm vlnov&aacute; d&eacute;lka) &ndash; modulov&aacute;no navigačn&iacute;mi k&oacute;dy P (pouze pro autorizovan&eacute; uživatele) a C/A (veřejně dostupn&eacute;)</li>
    <li><strong>L2 </strong>(1227,60Mhz 24cm) &ndash; modulov&aacute;no navigačn&iacute;m k&oacute;dem P</li>
    <li><strong>L5 </strong>(1176,45 MHz) &ndash; v&nbsp;r&aacute;mci projektu GPSIII pro civiln&iacute; užit&iacute;, modulov&aacute;no Neuman-Hofman k&oacute;dem</li>
    </ul>
    <p>Mezi z&aacute;kladn&iacute; sign&aacute;ly patř&iacute; t&eacute;ž <strong>družicov&aacute; navigačn&iacute; zpr&aacute;va</strong>, kter&aacute; obsahuje &uacute;daje o zdravotn&iacute;m stavu družic, pozici (efemeridy), korekce hodin, &uacute;daje o ostatn&iacute;ch družic&iacute;ch (almanach), ionosf&eacute;ře a společn&eacute; časov&eacute; z&aacute;kladně.</p>
    <p>Již několik let v&scaron;ak prob&iacute;h&aacute; tzv. &ldquo;modernizace GPS&rdquo;, kter&eacute; mnoh&eacute; měn&iacute;. Např. P a C/A k&oacute;d bude nahrazen M (obdoba P) a C (obdoba C/A) k&oacute;dem.</p>
    <hr /><h2>Sign&aacute;ly GLONASS a Galileo</h2>
    <p>U syst&eacute;mu <strong>GLONASS</strong> jsou nosn&eacute; frekvence modulov&aacute;ny k&oacute;dy HP (pouze pro autorizovan&eacute; uživatele; obdoba P u GPS) a SP (veřejn&eacute;; obdoba C/A u GPS). Mezi nosn&eacute; frekvence patř&iacute;:</p>
    <ul>
    <li>L1PT (1598&ndash;1605 MHz) &ndash; moduluje HP k&oacute;d a SP k&oacute;d</li>
    <li>L2PT (1242&ndash;1248 MHz) &ndash; moduluje HP k&oacute;d, na mlad&scaron;&iacute;ch družic&iacute;ch tak&eacute; SP k&oacute;d</li>
    <li>na mlad&scaron;&iacute;ch družic&iacute;ch dal&scaron;&iacute; sign&aacute;ly (i pro interoperabilitu s GPS a Galileo), rovněž navigačn&iacute; zpr&aacute;va</li>
    </ul>
    <p><strong>Galileo</strong> moduluje sv&eacute; sign&aacute;ly poskytovan&yacute;ch služeb, poskytuje v&iacute;ce sign&aacute;lů, z nichž s GPS se překr&yacute;v&aacute; band L1 (s L1 u GPS) a E5a (s L5 u GPS).</p>
    <hr /><h2>Faktory ovlivňuj&iacute;c&iacute; měřen&iacute;</h2>
    <p>Mezi hlavn&iacute; faktory ovlivňuj&iacute;c&iacute; měřen&iacute; pomoc&iacute; GNSS patř&iacute;:</p>
    <ul>
    <li><strong>nahodil&eacute; chyby (multipath) </strong>= v&iacute;cen&aacute;sobn&eacute; &scaron;&iacute;řen&iacute; sign&aacute;lu, kter&eacute; se odr&aacute;ž&iacute; od zemsk&eacute;ho povrchu, objektů či jin&yacute;ch povrchů (lze ře&scaron;it speci&aacute;ln&iacute; ant&eacute;nou)</li>
    <li><strong>systematick&eacute; chyby = </strong>vliv atmosf&eacute;ry, ve kter&eacute; doch&aacute;z&iacute; ke zpožděn&iacute; sign&aacute;lu při jeho &scaron;&iacute;řen&iacute; ionosf&eacute;rou (ionosf&eacute;rickou refrakci lze odstranit 2 f&aacute;zemi) a troposf&eacute;rou (troposf&eacute;rickou refrakci lze určit dle atmosf&eacute;rick&yacute;ch podm&iacute;nek)</li>
    </ul>
    <p>Mezi dal&scaron;&iacute; faktory patř&iacute;:</p>
    <ul>
    <li><strong>parametr přesnosti (DOP)</strong> = bezrozměrn&yacute; parametr ud&aacute;vaj&iacute;c&iacute; vliv geometrie prostorov&eacute;ho uspoř&aacute;d&aacute;n&iacute; družic GNSS a přij&iacute;mače v konkr&eacute;tn&iacute; epo&scaron;e na přesnost určen&iacute; polohy; lze určovat:
    <ul>
    <li>parametr horizont&aacute;ln&iacute; (HDOP),</li>
    <li>polohov&eacute; (PDOP),</li>
    <li>geometrick&eacute; (GDOP),</li>
    <li>vertik&aacute;ln&iacute; (VDOP)</li>
    <li>i časov&eacute; (TDOP) přesnosti.</li>
    </ul>
    </li>
    <li><strong>počet viditeln&yacute;ch družic</strong></li>
    <li><strong>stav družic</strong> (obsahuje navigačn&iacute; zpr&aacute;va)</li>
    <li><strong>synchronizace hodin / chyba hodin přij&iacute;mače</strong></li>
    <li><strong>poměr sign&aacute;l : &scaron;um</strong></li>
    <li><strong>vliv excentricit f&aacute;zov&yacute;ch center ant&eacute;n</strong> (lze ře&scaron;it kalibrac&iacute; ant&eacute;n)</li>
    </ul>

    <hr />
    <ImgCon><Gps /><div>
    <h2>Studijn&iacute; materi&aacute;ly Základy geodézie a GPS</h2>
    <p>T&eacute;ma <strong>Vysílané signály GNSS (GPS)</strong> je souč&aacute;st&iacute; <Link to="/zaklady-geodezie-gps/">studijn&iacute;ch materi&aacute;lů Základy geodézie a GPS (GNSS)</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předešlé (2. tlačítko) či n&aacute;sleduj&iacute;c&iacute;c&iacute; (3.&nbsp;tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/zaklady-geodezie-gps/"><button>Rozcestník geodézie a GPS</button></Link>
    <Link to="/zaklady-geodezie-gps/gnss-systemy/"><button className="inv">&larr; GNSS systémy a jejich složení</button></Link>
    <Link to="/zaklady-geodezie-gps/katastr-nemovitosti/"><button className="inv">Katastr nemovitostí &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
